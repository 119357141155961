import { createSlice, PayloadAction } from '@reduxjs/toolkit';

//import { AppThunk, AppDispatch } from './store'


export interface AudioInputInfo {
    label: string,
    deviceId: string
}

export interface RecordingState {
    isRecording: boolean;
    isPrimed: boolean;
    device: string |undefined;
    volume: number;
    peerId: string | undefined;


}
const initialState : RecordingState = {isRecording:false, isPrimed:false, device:undefined, volume:0, peerId:undefined};
const recordingStateSlice = createSlice({
    name: 'recordingState',
    initialState,
    reducers: {
        toggleRecording(state, action: PayloadAction<void>) {
            state.isRecording = !state.isRecording;
        },

        togglePrimed(state, action: PayloadAction<void>) {
            state.isPrimed = !state.isPrimed;
        },
 
        setDeviceConnected(state, action: PayloadAction<string>) {
            state.device = action.payload
        },

        setVolume(state, action: PayloadAction<number>) {
            state.volume =  state.volume*0.5 +action.payload*0.5
        },
        setPeerId(state, action:PayloadAction<string>) {
            state.peerId=action.payload;

        }

    }
});

export const { toggleRecording, togglePrimed, setDeviceConnected, setVolume, setPeerId } = recordingStateSlice.actions;

export default recordingStateSlice.reducer;