import store from '../context/store'
import { toggleRecording, togglePrimed, setVolume} from '../context/recordingSlice'
import {getVolume, indexOfPeak} from './helpers'
import { Peerer } from './peer';
import isClapWorkerNode from './workletnode'

//https://stackoverflow.com/questions/48757933/audiocontext-issue-on-safari?rq=1
declare global {
    interface Window {
      AudioContext: typeof AudioContext;
      webkitAudioContext: typeof AudioContext;
    }
  }

export const windowCtx =  window.AudioContext || window.webkitAudioContext;
const makeUserMediaOptions = (device:string):MediaStreamConstraints =>{
    return {
        "audio": {
            "deviceId":device,
            "autoGainControl": false,
            "echoCancellation":false,
            "noiseSuppression":false
        },
        "video": false
    };
}
/**
* This class choses which input device we want to record from and records raw data from the microphone
*/
export class Microphone {
    ctx: AudioContext;  
    peerer: undefined| Peerer;
    inputDeviceId: string;
    format: Object
    totalVolume: number;
    nVolume:number;
    mediaRecorder: MediaRecorder | undefined;
    scriptNode: ScriptProcessorNode | undefined;
    audioStream: MediaStream | undefined;
    streamSource: MediaStreamAudioSourceNode|undefined;
    clapWorkerNode: AudioWorkletNode |undefined;


    constructor(ctx:AudioContext) {
        this.peerer=undefined;
        this.ctx = ctx;
        this.inputDeviceId = "default";
        this.totalVolume=0;
        this.nVolume=0;
        this.format = {};
        this.mediaRecorder=undefined;
        this.scriptNode=undefined;
        this.audioStream=undefined;
        this.streamSource=undefined;
        this.clapWorkerNode=undefined;
    }
    /**
    * @param {string} deviceId: the id of the microphone we want to listen to
    */

    setPeerer = (peerer:Peerer) => {
        this.peerer = peerer
    }



    setInputDevice = (deviceId:string) =>{
        this.inputDeviceId=deviceId;
        this.connectMic();
    }

    maybeReset = () => {
        //This makes sure everything has stopped
        this.audioStream?.getTracks().forEach((track) => track.stop());
        this.streamSource?.disconnect();
        this.scriptNode?.disconnect();
        this.clapWorkerNode?.disconnect();
        this.mediaRecorder=undefined;        
    }


    connectMic = async () => {
        this.maybeReset()
        const options = makeUserMediaOptions(this.inputDeviceId)
        this.ctx.resume()
        this.audioStream = await navigator.mediaDevices.getUserMedia(options);
        this.streamSource = this.ctx.createMediaStreamSource(this.audioStream);

        this.mediaRecorder = new MediaRecorder(this.audioStream);
        this.mediaRecorder.ondataavailable = (event) => {
            this.peerer?.sendData(event.data)
        }
        if (process.env.REACT_APP_CLAPDETECTOR === "audioworklet") {
            console.log("using audioworklet")
            try {
                await this.ctx.audioWorklet.addModule("processor.js")
                this.clapWorkerNode = new isClapWorkerNode(this.ctx);
                const sens = this.clapWorkerNode.parameters.get("sens");
                sens?.setValueAtTime(10, 0) //Setting to 10 for now could be config
                this.clapWorkerNode.port.onmessage = (e) => {
                    const recordingState = store.getState().recordingState
                    switch(e.data.type) {              
                        case "clap":
                            if (recordingState.isPrimed) {
                                this.mediaRecorder?.start(250);
                                store.dispatch(togglePrimed());
                                store.dispatch(toggleRecording());
                                this.peerer?.sendRecording()
                            
                                
                            }
                            break
                        case "vol":
                            const newVol =e.data.vol * 100 
                            store.dispatch(setVolume(newVol));
                            this.peerer?.sendVolume(newVol, recordingState.isRecording)         
                    }    
                }
                this.streamSource.connect(this.clapWorkerNode);
                this.clapWorkerNode.connect(this.ctx.destination);
            }
            catch (e) {
                console.log(e)
            }
        }
        else {
            console.log("using script processor")

            let bufferSizeSinceLastUpdated = 0;
            this.scriptNode = this.ctx.createScriptProcessor(256, 1, 1);
            this.scriptNode.onaudioprocess = (event) => {
                const raw = event.inputBuffer.getChannelData(0);
                const volume = getVolume(raw);
                const recordingState = store.getState().recordingState;
                if (recordingState.isPrimed) {
                    if (indexOfPeak(raw, 0.01)) {                    
                        this.mediaRecorder?.start(250)
                        store.dispatch(togglePrimed());
                        store.dispatch(toggleRecording());
                        this.peerer?.sendRecording()          
                    } 
    
                }
                this.totalVolume+=volume;
                this.nVolume+=1
                if (bufferSizeSinceLastUpdated > 5000) {
                    const volToSend = this.totalVolume/this.nVolume
                    this.peerer?.sendVolume(volToSend*100, recordingState.isRecording);
                    store.dispatch(setVolume(volToSend*100))
                    bufferSizeSinceLastUpdated = 0
                    this.totalVolume=0;
                    this.nVolume=0
                }
                bufferSizeSinceLastUpdated += event.inputBuffer.length
            }
            this.streamSource.connect(this.scriptNode);
            this.scriptNode.connect(this.ctx.destination);
        }
    
    }


}
