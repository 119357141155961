//Libs
import {Meter, Box} from 'grommet';

import { useSelector } from 'react-redux';

import { RootState } from '../../context/rootReducer';

const VolumeBar = (props:any):JSX.Element => {

  const volume = useSelector((state:RootState) => state.recordingState.volume); 
  const isPrimed = useSelector((state:RootState) => state.recordingState.isPrimed);
  const isRecording =  useSelector((state:RootState) => state.recordingState.isRecording);
  const color = isRecording?"recording":isPrimed?"primed":"brand";   
    return (
 
          <Box gap="small"  pad={{ left: 'none', right: 'none', vertical: 'xxsmall' }}>
            <Meter
            type="circle"
            size="medium"
            thickness="large"
            max={80}
            values={[{
                color:color,
                value:volume
            }]}
            />
          </Box>
    
    );
  }

export default VolumeBar;
