
//Libs
import React from 'react';
import {Box} from 'grommet'

//My Components
import ChoseMic from './ChoseMic';
import VolumeBar from './VolumeBar';
import NameInput from './NameInput';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/rootReducer';

const Record = (props:any):JSX.Element => {
    const projectId = useSelector((state:RootState) => state.recordingState.peerId); 

    return (
       
        <Box  align="center"   width="98%" pad={{vertical:"small"}}  gap="medium">
             {projectId?
             <Box>
            <Box direction='column' align='center'> 
                <ChoseMic micInterface={props.micInterface} />    
            </Box>
            <Box direction='column' fill={true} align='center' gap="medium">
                <VolumeBar/>
                <NameInput peerInterface={props.peerInterface}/>
            </Box>
            </Box>:
                "Connecting..."
             }
        </Box>

    );
  }

export default Record;