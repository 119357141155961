


export interface AudioInputInfo {
    label: string,
    deviceId: string
}



export const getAvailableAudioDevices =  async():Promise<AudioInputInfo[]> => {

    await navigator.mediaDevices.getUserMedia({audio: true, video: false});
    let devices = await navigator.mediaDevices.enumerateDevices();

    return devices
    .filter((device:MediaDeviceInfo) => device.kind==="audioinput")
    .map(( device:MediaDeviceInfo):AudioInputInfo => ({label:device.label, deviceId: device.deviceId}))   
}



export const getVolume  = (rawAudio:Float32Array) => {
	const total =rawAudio.reduce((acc, elem) => acc + elem*elem, 0); 
	return Math.sqrt(total/(rawAudio.length));
}

export const indexOfPeak = (rawAudio:Float32Array, base:number): number => {
    var i;
    const threshold = base*40;
    let splitIndex= 0;
    for (i = 0; i < rawAudio.length; i++) {
        if (Math.sqrt(rawAudio[i]) > threshold) {
            return i
        }
    }
    return splitIndex
}



