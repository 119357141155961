import './main.css'


const brandColor = '#261c15';


const theme = {
    global: {
      colors: {
        behind: '#F4F9FD',
        second: '#f06d23',
        brand:brandColor,
        recording:'#c82536',
        primed:'#FFB347',
        selected: '#261c15',
        focus: '#261c15'
      },
      font: {
        family: 'Cutive Mono',
        size: '12px',
        height: '10px'

      },
    },
  };


export const micColors= ["#E5E28B", "#A7DB8C", "#B4A7EB", "#F3A5BC", "#A0D8E9", brandColor]

export default theme;