import Peer from "peerjs";
import store from '../context/store'
import {togglePrimed, toggleRecording, setPeerId} from '../context/recordingSlice'
import { Microphone } from "./capture";


const options = {
    config:{'iceServers': [{ 'urls': 'stun:stun.l.google.com:19302' }]},
    host: process.env.REACT_APP_PEERJS_SERVER,
    secure:true,
    key: "supersecret",
    path:"/myapp"         
};



const getProjectIdfromURL = ():string => {
    const url = window.location.href;
        let splits = url.split("/")
        return splits[splits.length-1];
  }

export class Peerer {
    peerer: Peer
    projectId: string
    myId: string |undefined
    conn:any
    mic: undefined | Microphone
    pingTimer: any
    constructor() {

        this.projectId= getProjectIdfromURL();;
        this.myId=undefined;
        this.peerer = new Peer(options);
        this.peerer.on('open', this._handleOpen);
        this.conn = undefined;
        this.mic = undefined;
        this.pingTimer = undefined;
    }


    setMic = (mic:Microphone) => {
        this.mic = mic
    }


    sendData = (blob:Blob) => {
        this.conn.send({type:"micData", id:this.myId, value:blob})
    }

    sendName = (name:string) => {
        this.conn.send({type:"name", id:this.myId, value:name})

    }
    sendVolume = (volume:number, isRecording:boolean) => {
        this.conn.send({type:"volume", id:this.myId, isRecording, value:volume})
    }

    sendPrimed = () => {
        this.conn.send({type:"primed", id:this.myId})
    }

    sendRecording = () => {
        this.conn.send({type:"recording", id:this.myId})
    }
    sendPing = () => {
        this.conn.send({type:"ping", id:this.myId})

    }

    _handleOpen = (myId:string) => {
        this.conn = this.peerer.connect(this.projectId);
        this.conn.on("open", () =>  store.dispatch(setPeerId(myId)));
        this.conn.on("data", this._handleData)
        this.myId=myId;
        this._startPing();
        

        window.onbeforeunload = (e:any) => {
            this.conn.close();
            clearInterval(this.pingTimer);
        }
    }

    _startPing =() => {
        this.pingTimer = setInterval(this.sendPing, 3000)
    }

    _handleData = (data:any) => {      
        switch (data.type) {
            case "prime":
                this._handlePrime(data);
                this.sendPrimed();
                break;
            case "stop":
                this._handleStop(data);
                this.sendPrimed();
                break;
            case "reset":
                this._handleReset(data)
                break;
        }
          
    }

    _handlePrime = (data:any) =>{
        store.dispatch(togglePrimed())
    }

    _handleStop = (data:any) =>{
        store.dispatch(toggleRecording())
        this.mic?.mediaRecorder?.stop()
        this.sendRecording();
    }
    _handleReset = (data:any) => {
        const state = store.getState().recordingState
        if (state.isRecording) {
            store.dispatch(toggleRecording())
        }
        if (state.isPrimed) {
            store.dispatch(togglePrimed())
        }
        this.mic?.connectMic();


    }

  



}