
import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import NoSleep from 'nosleep.js';

import {Select, Box} from 'grommet';
import {Microphone} from 'grommet-icons'

import {getAvailableAudioDevices, AudioInputInfo} from '../../media/helpers'

import { RootState } from '../../context/rootReducer';
import {setDeviceConnected} from '../../context/recordingSlice';

const ChoseMic = (props:any):JSX.Element => {

    const dispatch = useDispatch();
    const [avDevices, setAvDevices] = useState<AudioInputInfo[]>([]);
    const deviceSelected = useSelector((state:RootState) => state.recordingState.device); 
    const noSleep = new NoSleep();
    //const avDevices = useSelector((state:RootState) => state.recordingState.avDevices); 


    useEffect(
      //Here we figure out what devices are avialable
      () => {
        getAvailableAudioDevices().then((devices:AudioInputInfo[]) => {
          if (devices) {setAvDevices(devices)}
          
        });
      }, [dispatch]);
  
    const onChange = (event:any) => {
      
      dispatch(setDeviceConnected(event.value));
  
      props.micInterface.setInputDevice(event.value.deviceId);
      props.micInterface.ctx.resume()

      //Stop sleeping
      noSleep.enable();
      
      
    }
  
    return (   
        <Box direction='row' align='center' gap="small"pad={{vertical:"xsmall"}}> 
          <Microphone/>
          <Select
            size={"small"}
            labelKey={"label"}
            options={avDevices}
            value={deviceSelected}
            valueKey={"deviceId"}
            onChange={onChange}
            
          />
        </Box>
      
    );
  }

export default ChoseMic;